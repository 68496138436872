import '../styles/popup.scss';

import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Controller,useForm } from 'react-hook-form';
import { geocodeByAddress } from 'react-places-autocomplete';
import Swal from 'sweetalert2';

import svg3 from '../assets/images/baggage-2.svg';
import bamboo from '../assets/images/bamboo.png';
import svg7 from '../assets/images/bandage-2.svg';
import svg10 from '../assets/images/bandage-2.svg';
import coverage from '../assets/images/coverage.png';
import svg2 from '../assets/images/family-8.svg';
import svg4 from '../assets/images/faucet-1.svg';
import frame1 from '../assets/images/Frame.svg';
import frame2 from '../assets/images/Frame2.svg';
import frame3 from '../assets/images/Frame3.svg';
import frame4 from '../assets/images/Frame4.svg';
import frame5 from '../assets/images/Frame5.svg';
import hippo from '../assets/images/hippo.png';
import svg6 from '../assets/images/id-card-1.svg';
import svg9 from '../assets/images/id-card-1.svg';
import footlogo from '../assets/images/logo-w-504.svg';
import svg1 from '../assets/images/medical-report-2.svg';
import nationwide from '../assets/images/Nationwideactiveweb.png';
import svg8 from '../assets/images/pendant-1.svg';
import svg11 from '../assets/images/pendant-1.svg';
import svg5 from '../assets/images/pet.svg';
import safeco from '../assets/images/safecoactiveweb.png';
import stillwater from '../assets/images/stillwateractiveweb.png';
import fullstar from '../assets/images/strfull.svg';
import fullstarone from '../assets/images/strfull-1.svg';
import svg12 from '../assets/images/television.svg';
import Maps from '../components/google';
import { getZipCode } from '../helpers/address';
import { statesMap } from '../helpers/statesMap';


const CssTextField = styled( TextField )( {
  '& label.Mui-focused': {
    color: '#646464'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#DAE7EC'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DAE7EC'
    },
    '&:hover fieldset': {
      borderColor: '#DAE7EC'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DAE7EC'
    }
  }
} );


function PopUp( { location } ) {

  let client_id = '', short_url = '', home_quote = '', cta_url = '';

  const [auth0_id, setAuthId] = useState( '' );
  const [address_details, setAddressDetails] = useState( {} );
  const [client, setClient] = useState( {} );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm();

  const [address,setAddress] = useState( '' );
  const [addressFromParams, setParamsAddress] = useState( '' );
  const [showPassword, passwordField] = useState( false );
  const [addressError, setAddressError] = useState( '' );
  const [allowedStatesString, setAllowedStates] = useState( '' );

  const onSubmit = async( data ) => {
    // Handle form submission logic here
    if( !data )
      setAddressError( 'Enter Valid Address' );
    data.address = address;
    const notEmpty = Object.values( data ).every( value => Boolean( value ) );
    if( notEmpty )
    {
      // const element = document.getElementById( 'address-field' );
      // console.log( 'element',element.target.value );
      // console.log( 'vardha',data );
    }
    else{
      setAddressError( 'Enter Valid Address' );
    }
    console.log( address_details );
    const body = {
      lead_source: 'zipform',
      full_address: address,
      ...data,
      ...address_details,
      ...client,
      password: data.password?.trim() || ''
    };
    console.log( client_id, 'client' );

    if( auth0_id ){
      body.auth0_id = auth0_id;
    }

    const response = await fetch( process.env.GATSBY_API_BASE_URL + '/lonewolf', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },body: JSON.stringify( body )
    } );

    const { url } = await response.json();

    navigate( url?.replace( window.origin, '' ) );
  };

  function paramsToObject( entries ) {
    const result = {};
    for( const [key, value] of entries ) {
      result[key] = value;
    }
    return result;
  }

  useEffect( () => {
    const urlParams = new URLSearchParams( location.search );
    const entries = urlParams.entries();
    const params = paramsToObject( entries );
    console.log( params, 'params' );
    const intialValues = Object.assign( {},params );
    document.querySelector( 'body' ).style.overflowX = 'auto';
    setValue( 'sellerEmail', params.agent_email );
    setValue( 'sellerName', params.agent_name );
    setValue( 'sellerPhone', params.agent_phone ? phonelength( params.agent_phone ) : '' );
    setValue( 'ownerEmail', params.client_email );
    setValue( 'ownerName', params.client_name );
    setValue( 'ownerPhone', params.client_phone ? phonelength( params.client_phone ) : '' );
    setAddress( params.full_address );
    setParamsAddress( params.full_address );
    setAuthId( params.auth0_id );

    if( params.client_id ){
      client_id = params.client_id;
      short_url = params.short_url;
      home_quote = params.home_quote;
      cta_url = params.cta_url;
      setClient( { client_id,
        short_url,
        home_quote,
        cta_url
      } );
    }

    window.history.replaceState( {}, document.title, '/' + 'agents' );

    // agent_email, agent_name, agent_last_name, agent_phone

    // client_id, full_address, client_name, client_phone, client_email
    // setEmail( params.email || '' );
  }, [location,setValue] );

  async function handleAddressSelect( address, field ){
    const results = await geocodeByAddress( address );
    field.onChange( results[0].formatted_address );
    setAddress( results[0].formatted_address );
    setAddressDetails( getZipCode( results ) );
    console.log( address_details );
  }

  useEffect( () => {
    fetch( process.env.GATSBY_NEST_APP_URL + 'states' )
      .then( resp => resp.json() )
      .then( ( states ) =>{
        const length = states?.auto?.length;
        let allowedStatesString = '';
        allowedStatesString = 'Bubble presently offers home insurance coverage within the following markets: ' + allowedStatesString;
        // allowedStatesString = allowedStatesString.replace( /,(?=[^,]

        for( let i = 0; i < states?.auto?.length; i++ ){
          const  state = states.auto[i];
          if( i + 2  < length ){
            allowedStatesString += statesMap[state] + ', ';
          } else if( i + 1 < length ){
            allowedStatesString += statesMap[state] + ' and ';
          }else {
            allowedStatesString += statesMap[state] + '.';
          }
        }

        setAllowedStates( allowedStatesString );
      } );
  }, [] );

  async function addressChange( address, field ){
    field.onChange( '' );
    // setZip( zip );
    // setStateName( stateName );;
  }


  function phonelength( e ) {

    let count = 0;
    let phone;
    phone = e?.toString();
    phone = phone.replace( /^0+/, '' );
    phone = phone.replace( '+1', '' ).replace( '(', '' ).replace( ')', '' );
    const phonestring = phone.toString().slice( 0, phone.length );
    phone = phonestring.replace( /(\d{3})-?/g, function ( m, a ) {
      return ++count <= 2 ? a + '-' : m;
    } );
    phone = phone.slice( 0, 13 );
    // this.validateForm();
    let phoneNumber = phone;

    const j = phoneNumber.replace( /-/g, '' );
    var K = j.replace( /[{()}]/g, '' );
    K = K.replace( / /g, '' );
    const L = K.replace( '+1', '' );
    const A = L.substring( 0, 3 );
    const B = L.substring( 3, 6 );
    const C = L.substring( 6, 10 );
    if ( A != '' && K.length <= 3 ) {
      if ( A.length == 4  ) {
        phoneNumber = '+1(' + A + ') ';
      } else {
        phoneNumber = '+1(' + A;
      }
    } else if ( B != '' && K.length > 3 && K.length < 7 ) {
      phoneNumber = '+1(' + A + ') ' + B;
    } else if ( B != '' && K.length >= 7 ) {
      phoneNumber = '+1(' + A + ') ' + B + '-' + C;
    } else {
      if ( L == '' ) {
        phoneNumber = '';
      }

    }
    phone = phoneNumber.replace( '+1', '' ).replace( '(', '' ).replace( ')', '' ).replace( '-', '' ).replace( ' ', '' );
    return phoneNumber;
  }

  function openStatesPopUp( e ){
    e.preventDefault();
    Swal.fire( {
      // imageUrl: images,
      // imageHeight: 151,
      // imageWidth: 125,
      title: '',
      customClass: 'agents-popup',
      text: allowedStatesString,
      type: 'warning'
    } );
  }


  return(
    <>
      <section className="preheader">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <a href="http://getmybubble.com/" className="logo">
              <img src="https://pages.getmybubble.com/hubfs/Landing%20Page%20Images%20Folder/group-19.svg" loading="lazy" alt="" />
            </a>

            <div className="ml-auto text-center hdrright">
              <a href="tel:(833) 900-BUBB" className="prehdrbtn">
                <div className="prehdrtit">Contact us</div>

                <div className="prehdrph">(833) 900-BUBB</div>
              </a>

              <div className="prehdrday">Mon-Fri: 9AM - 8PM ET</div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid" id="blurout">
        <div className="row">
          <div className="col-lg-6 deskview">
            <div className="overflowstyleforoneinr">
              <div className="text-center coverimgsec">
                <div className="coverimgtit">Hover over the home to see how Bubble protects you</div>

                <div className="coverimg">
                  <img src={coverage} loading="lazy" className="" alt="" />

                  <div className="circle circle-1">
                    <div className="circleconwrp toolt">
                      <div className="circlecon top text-center">
                        <h4>Other structures</h4>

                        <div className="tooltxt">
                          Similar to dwelling coverage but for unattached structures
                        </div>

                        <i></i>
                      </div>
                    </div>
                  </div>

                  <div className="circle circle-2">
                    <div className="circleconwrp toolt">
                      <div className="circlecon right text-center">
                        <h4>Dwelling</h4>

                        <div className="tooltxt">
                          Pays to repair or rebuild your home and attached structures up tp chosen coverage limits
                        </div>

                        <i></i>
                      </div>
                    </div>
                  </div>

                  <div className="circle circle-3">
                    <div className="circleconwrp toolt">
                      <div className="circlecon bottom text-center">
                        <h4>Personal property</h4>

                        <div className="tooltxt">
                          Pays for damage to or theft of most personal items including furniture, appliances and cloting
                        </div>

                        <i></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="maincov mb-4">
                <div className="maincovtit text-center">Other main coverages</div>

                <div className="row spacemargin">
                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg1} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Medical payments</div>
                      </div>

                      <div className="maincardtext">Pays for medical expenses for bodily injury to guests while on your property regardless of fault.</div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg2} loading="lazy" alt="" />
                        </span>

                        <div className="maincardtit">Personal liability</div>
                      </div>

                      <div className="maincardtext">Pays for expenses associated with bodily injury or property damage to others due to negligence on your part both on or off your property.</div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg3} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Loss of use</div>
                      </div>

                      <div className="maincardtext">Pays the bills or expenses you have as a result of losing the use or your residence.</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="maincov">
                <div className="maincovtit text-center">Other Coverage Included</div>

                <div className="row spacemargin">
                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg4} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Water backup</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg5} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Animal liability</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg6} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Identity theft protection</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg7} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Personal injury</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg8} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Scheduled personal property</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg9} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Identity theft protection</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg10}loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Personal injury</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg11} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Scheduled personal property</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="maincard h-100">
                      <div className="h-100 d-flex align-items-center maincardheading">
                        <span>
                          <img src={svg12} loading="lazy"  alt="" />
                        </span>

                        <div className="maincardtit">Special personal property</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="frmbg">
                  <div className="frmhdr text-center">
                    <h3>Get competitive home insurance quotes for your client, with just one click.</h3>

                    <p className="p-0 m-0">Smart and Affordable Home insurance</p>
                  </div>

                  <div className="text-center logosec">
                    <h3>We partner with best</h3>

                    <div className="row mgb-30">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-4">
                            <div className="logosecimg">
                              <img className="d-none d-sm-none d-md-block" src={stillwater} width="150" height="37.5" alt="stillwater" />

                              <img className="d-block d-sm-block d-md-none" src={stillwater} width="100"  alt="stillwater" />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="logosecimg">
                              <img className="d-none d-sm-none d-md-block" src={nationwide} width="129" height="52" alt="nationwide" />

                              <img className="d-block d-sm-block d-md-none" src={nationwide} width="96" alt="nationwide" />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="logosecimg">
                              <img className="d-none d-sm-none d-md-block" src={safeco} width="160" height="40" alt="safeco" />

                              <img className="d-block d-sm-block d-md-none" src={safeco} width="70" alt="safeco" />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="logosecimg">
                              <img className="d-none d-sm-none d-md-block" src={hippo} width="80" height="40" alt="hippo" />

                              <img className="d-block d-sm-block d-md-none" src={hippo} width="70" alt="hippo" />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="logosecimg">
                              <img className="d-none d-sm-none d-md-block" src={bamboo} width="100" height="40" alt="bamboo" />

                              <img className="d-block d-sm-block d-md-none" src={bamboo} width="70" alt="bamboo" />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="logosecimg">
                              <img className="d-none d-sm-none d-md-block" src="https://app.getmybubble.co/v2/assets/Images/images/lemonade.svg" width="160" height="40" alt="lemonade" />

                              <img className="d-block d-sm-block d-md-none" src="https://app.getmybubble.co/v2/assets/Images/images/lemonade.svg" width="70" alt="lemonade" />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit( onSubmit )}>
                    <div className="prefrm">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">

                            <Controller
                              name="sellerEmail"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                pattern: {
                                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                                  message: 'Invalid Seller Email address'
                                }
                              }}
                              render={( { field } ) =>
                                ( <CssTextField
                                  id="sellerEmail"
                                  label="Selling Agent Email"
                                  className="commonwidth"
                                  sx={{ marginTop:'8px' }}
                                  helperText={errors.sellerEmail ? errors.sellerEmail.message : ''}
                                  error={errors.sellerEmail}
                                  variant="outlined"
                                  {...field}        /> )
                              }
                            />

                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">

                            <Controller
                              name="sellerName"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                minLength: {
                                  value: 3,
                                  message: 'Seller Name must be at least 3 characters long'
                                },
                                maxLength: {
                                  value: 20,
                                  message: 'Seller Name cannot exceed 20 characters'
                                },
                                pattern: {
                                  value :/^[a-zA-Z][a-zA-Z -\']+$/,
                                  message:'Please enter a valid Seller Name '
                                }
                              }}
                              render={( { field } ) =>
                                ( <CssTextField
                                  id="sellerName"
                                  label="Selling Agent Name"
                                  className="commonwidth"
                                  sx={{ marginTop:'8px' }}
                                  helperText={errors.sellerName ? errors.sellerName.message : ''}
                                  error={errors.sellerName}
                                  variant="outlined"
                                  {...field}        /> )
                              }
                            />

                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">

                            <Controller
                              name="sellerPhone"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                pattern: {
                                  value : /\+1\(\d{3}\) \d{3}-\d{4}/,
                                  message:'Please enter a valid Seller phone '
                                }

                              }}
                              render={( { field } ) =>
                                ( <CssTextField
                                  id="sellerPhone"
                                  label="Selling Agent phone"
                                  className="commonwidth"
                                  sx={{ marginTop:'8px' }}
                                  helperText={errors.sellerPhone ? errors.sellerPhone.message : ''}
                                  error={errors.sellerPhone}
                                  variant="outlined"
                                  {...field}
                                  onChange={( e ) => field.onChange( phonelength( e.target.value ) )}
                                /> )
                              }
                            />
                          </div>
                        </div>

                        {
                          !auth0_id &&
                        <div className="col-md-6">
                          <div className="form-group">

                            <Controller
                              name="password"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                pattern: {
                                  value : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                                  message:'Please enter a valid password(Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character) '
                                }

                              }}
                              render={( { field } ) =>
                                ( <>
                                  <CssTextField
                                    id="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    className="commonwidth"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {showPassword ?
                                            <IconButton onClick={() => passwordField( false )}>
                                              <VisibilityOffIcon />
                                            </IconButton>

                                            :
                                            <IconButton onClick={() => passwordField( true )}>
                                              <VisibilityIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ) }}
                                    sx={{ marginTop:'8px' }}
                                    helperText={errors.password ? errors.password.message : ''}
                                    error={errors.password}
                                    variant="outlined"
                                    {...field}
                                  />

                                  <div className='info'>
                                    Use this password for Bubble agent portal
                                  </div>
                                </> )
                              }
                            />
                          </div>
                        </div>
                        }
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="parent">

                            <Controller
                              name="Property address"
                              control={control}

                              rules={{
                                required: 'This field is required'

                              }}
                              render={( { field } ) =>
                                ( <Maps
                                  id="Address"
                                  label="Property Address"
                                  sx={{ marginTop:'8px' }}
                                  helperText={errors.address ? errors.address.message : ''}
                                  error={errors.address}
                                  variant="outlined"
                                  placeholder={'Property Address'}
                                  defaultValue={addressFromParams}
                                  getAddress={( e ) =>  handleAddressSelect( e,field  )}
                                  addressHandle={( e ) => addressChange( e,field ) }
                                  field={field}

                                /> )
                              }
                            />

                            <div className="info">
                              <a href="#" onClick={openStatesPopUp}>
                              Check which states are supported by Bubble today
                              </a>
                            </div>

                            {/* <Maps placeholder={'Address'} getAddress={handleAddressSelect} addressError={addressError}  /> */}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">

                            <Controller
                              name="ownerName"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                minLength: {
                                  value: 3,
                                  message: 'Owner Name must be at least 3 characters long'
                                },
                                maxLength: {
                                  value: 20,
                                  message: 'Owner Name cannot exceed 20 characters'
                                },
                                pattern: {
                                  value :/^[a-zA-Z][a-zA-Z -\']+$/,
                                  message:'Please enter a valid Owner Name '
                                }
                              }}
                              render={( { field } ) =>
                                ( <CssTextField
                                  id="ownerName"
                                  label="Property Owner's Name"
                                  className="commonwidth"
                                  helperText={errors.ownerName ? errors.ownerName.message : ''}
                                  error={errors.ownerName}
                                  sx={{ marginTop:'8px' }}
                                  variant="outlined"
                                  {...field}        /> )
                              }
                            />


                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <Controller
                              name="ownerPhone"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                pattern: {
                                  value :/\+1\(\d{3}\) \d{3}-\d{4}/,
                                  message:'Please enter a valid Owner phone '
                                }
                              }}
                              render={( { field } ) =>
                                ( <CssTextField
                                  id="ownerPhone"
                                  label="Property Owner's phone"
                                  className="commonwidth"
                                  sx={{ marginTop:'8px' }}
                                  helperText={errors.ownerPhone ? errors.ownerPhone.message : ''}
                                  error={errors.ownerPhone}
                                  variant="outlined"
                                  {...field}
                                  onChange={( e ) => field.onChange( phonelength( e.target.value ) )}/>
                                )
                              }
                            />

                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <Controller
                              name="ownerEmail"
                              control={control}

                              rules={{
                                required: 'This field is required',
                                pattern: {
                                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                                  message: 'Invalid Owner Email address'
                                }
                              }}
                              render={( { field } ) =>
                                ( <CssTextField
                                  id="ownerEmail"
                                  label="Property Owner's Email"
                                  className="commonwidth"
                                  sx={{ marginTop:'8px' }}
                                  helperText={errors.ownerEmail ? errors.ownerEmail.message : ''}
                                  error={errors.ownerEmail}
                                  variant="outlined"
                                  {...field}        /> )
                              }
                            />

                          </div>


                        </div>
                      </div>

                    </div>


                    {/* <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            {/* <Autocomplete
                              id="country-select-demo"
                              sx={{ width: 300 }}
                              options="world"
                              autoHighlight
                              getOptionLabel={( option ) => option.label}
                              renderOption={( props, option ) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                )}
                              />

                                  {option.label}

                                  ({option.code}) +

                                  {option.phone}
                                </Box>
                              )}
                              renderInput={( params ) => (
                                <TextField
                                  {...params}
                                  label="Address"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />

                          </div>
                        </div>
                      </div> */}


                    {/*
                      <div className='row'>
                        <div className='col-12'>
                          <FormGroup>
                            <FormControlLabel className='chklbl' required control={<Checkbox />} label="The information below is sent to the Service Provider and the Buyers, please verify its accuracy and press 'Send' to 'proceed'" />
                          </FormGroup>
                        </div>
                      </div> */}


                    <div className="prefrmpolicy">
                      <p>On clicking "Send Home Insurance Quotes” you give your signed permission for Bubble to contact your clients at the email and telephone number provided, including via text message and live, automated, and pre-recorded telephone calls, even if your number is on a Do Not Call list. You also acknowledge that you have received and agree to the Privacy Policy and Consent to do Business Electronically.</p>

                      <p>Your consents for your clients to receive text messages and telephone calls and to do business electronically are not required to receive services from Bubble; you may instead reach us directly at (833) 900-BUBB. You can revoke these consents at any time by emailing privacy@getmybubble.com</p>
                    </div>

                    <button type="submit" className="btnorng">
                            Send
                    </button>
                  </form>
                </div>
              </div>

              <br />
            </div>
          </div>
        </div>
      </div>

      <section className="prebdywrp" id="PreFill_id">
        <div className="prebdy">
          <div className="prefrmsec">
            {/* <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-lg-7 pl-lg-4">
                  <div className="partsec mobview">
                    <h4>Partnered with the best</h4>

                    <div className="row align-items-center">
                      <div className="col-3">
                        <img src="assets/images/images/stilllogo.jpg" loading="lazy" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="container-fluid">
              <div className="addinsusec hminssec">
                <div className="addinsusechdr">
                  <h2>Home Insurance</h2>
                </div>

                <div className="addinsuscroll hminsu">
                  <div className="addinsu">
                    <div className="row">


                      <div className="col5">
                        <div className="addinsuinr">
                          <span><img src={frame1} loading="lazy"  alt="" /></span>

                          <p className="a_text">Discounts that save you money
                          </p>
                        </div>
                      </div>

                      <div className="col5">
                        <div className="addinsuinr">
                          <span><img src={frame2} loading="lazy"  alt="" /></span>

                          <p className="a_text">Covers most personal items including appliances, furniture, electronics, and clothing</p>
                        </div>
                      </div>

                      <div className="col5">
                        <div className="addinsuinr">
                          <span><img src={frame3} loading="lazy"  alt="" /></span>

                          <p>Pays the replacement cost for your home's contents</p>
                        </div>
                      </div>

                      <div className="col5">
                        <div className="addinsuinr">
                          <span><img src={frame4} loading="lazy"  alt="" /></span>

                          <p>Pays claims based on the cost to rebuild your home</p>
                        </div>
                      </div>

                      <div className="col5">
                        <div className="addinsuinr">
                          <span><img src={frame5} loading="lazy"  alt="" /></span>

                          <p className="a_text">Pays for hotel expenses incurred in case your home is too damaged to live in</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="discsec">
                <div className="addinsusechdr">
                  <h2 style={{ fontWeight: '400;' }}>Discounts with Home Insurance</h2>
                </div>

                <div className="discsecborder mb-2">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 text-center">
                      <div className="discinr">
                        <div className="discround d-flex align-items-center justify-content-center">
                          <div className="discroundinr">
                            <small className="d-block">Up to</small>

                            <strong>8<span>%</span></strong>
                          </div>
                        </div>

                        <p>Married / With Partner</p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 text-center">
                      <div className="discinr">
                        <div className="discround d-flex align-items-center justify-content-center">
                          <div className="discroundinr">
                            <small className="d-block">Up to</small>

                            <strong>10<span>%</span></strong>
                          </div>
                        </div>

                        <p>Roof made with clay, terra cotta, slate, concrete, or metal</p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 text-center">
                      <div className="discinr">
                        <div className="discround d-flex align-items-center justify-content-center">
                          <div className="discroundinr">
                            <small className="d-block">Up to</small>

                            <strong>10<span>%</span></strong>
                          </div>
                        </div>

                        <p>Construction by an accredited builder
                          <em className="d-block">(current year)</em>
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 text-center">
                      <div className="discinr">
                        <div className="discround d-flex align-items-center justify-content-center">
                          <div className="discroundinr">
                            <small className="d-block">Up to</small>

                            <strong>10<span>%</span></strong>
                          </div>
                        </div>

                        <p>Newly acquired home
                          <em>(first year)</em>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <div className="applitxt">*As Applicable</div>
                </div>
              </div>
            </div>


            <div className="container-fluid">
              <div className="testisec">
                <div className="lifinsusechdr">
                  <h2>Customer testimonials</h2>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="testiinr h-100">
                      <div className="str">
                        <img src={fullstar} loading="lazy" alt="" />
                      </div>

                      <h3>Marvin W</h3>

                      <div className="testisub">Casa Grande, Arizona</div>

                      <div className="testiinrsub">Carrier - Stillwaters and Pacific Life</div>

                      <p>Home and life insurance is very important to me. I'm a career trucker that's always on the move. I can't always be home to take care of my family, America needs to keep on moving. I drive on the roads of uncertainty, where
                        anything can happen and it usually does. Bubble insurance has become my security blanket against those worries. Being a new home owner makes it essential to not only secure my families best interest while I'm here,
                        but just in case I'm not I know my family will be OK. Thank you Bubble Insurance.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="testiinr h-100">
                      <div className="str">
                        <img src={fullstar} loading="lazy" alt="" />
                      </div>

                      <h3>Lauren S</h3>

                      <div className="testisub">Lake Elsinore, California</div>

                      <div className="testiinrsub">Carrier - Stillwaters</div>

                      <p>Freedom & safety are most important to me, especially in a post-2020 world. Insurance makes it so I feel some comfort knowing whatever may happen, I'm covered and safe financially. My experience with Bubble was friendly,
                        efficient, & transparent. They were able to match or beat the other quotes I provided in a timely manner. Keep up the great work! I would refer them over!
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="testiinr h-100">
                      <div className="str">
                        <img src={fullstar} loading="lazy" alt="" />
                      </div>

                      <h3>Ed W</h3>

                      <div className="testisub">San Diego, California</div>

                      <div className="testiinrsub">Carrier - Stillwaters</div>

                      <p>Recently had a fire inside the electrical box at our house. Damage was contained within the panel box, but with a 3 and 1 year old in the house, it needed to be fixed quickly. Stillwater Insurance understood that, and allowed
                        repairs to begin even before they inspected the damage. By the time they got out to see the damage, the panel had already been replaced. Stillwater came through....
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="testiinr h-100">
                      <div className="str">
                        <img src={fullstarone} loading="lazy" alt="" />
                      </div>

                      <h3>Peyon K</h3>

                      <div className="testisub">San Diego, California</div>

                      <div className="testiinrsub">Carrier - Stillwaters</div>

                      <p>We recently had a flood and had to move out for five weeks. Yvonne at Stillwater took excellent care of us. Found a hotel and had it direct billed to the company. She handled all the details concerning damage quickly and
                        in a professional and friendly manner. I highly recommend them! You can tell they really care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      <div className="prefooter">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <a href="" className="logo">
                <img width="156" height="40" src={footlogo} loading="lazy" alt="" />
              </a>

              <div className="presoc mobview">
                <a href="https://www.facebook.com/GetMyBubble" target="blank">
                  <img src="assets/images/images/fb.svg" loading="lazy" alt="" />
                </a>

                <a href="https://twitter.com/getmybubble" target="blank">
                  <img src="assets/images/images/twi.svg" loading="lazy" alt="" />
                </a>

                <a href="https://www.linkedin.com/company/getmybubble" target="blank">
                  <img src="assets/images/images/lin.svg" loading="lazy" alt="" />
                </a>
              </div>

              <div className="preftrcon">
                <p>Bubble's mission is to be the simplest, easiest, and the most convenient way for people to protect themselves, their family, and properties for complete peace of mind.</p>

                <strong className="d-block">GETMYBUBBLE<sup>TM</sup></strong>

                <strong className="d-block">©Bubble Insurance Solutions, 2023 | All rights reserved<br />Made with ❤️ in
                    California. </strong>
              </div>
            </div>

            <div className="col-md-6">
              <div className="text-right presoc webview">
                <div className="text-right presoc">
                  <a href="https://www.facebook.com/GetMyBubble" target="blank">
                    <img src="assets/images/images/fb.svg" loading="lazy" alt="" />
                  </a>

                  <a href="https://twitter.com/getmybubble" target="blank">
                    <img src="assets/images/images/twi.svg" loading="lazy" alt="" />
                  </a>

                  <a href="https://www.linkedin.com/company/getmybubble" target="blank">
                    <img src="assets/images/images/lin.svg" loading="lazy" alt="" />
                  </a>
                </div>
              </div>

              <div className="preftrcon">
                <strong className="d-block mb-2">
                  <a href="https://www.getmybubble.com/license-numbers" target="blank" style={{ color: 'white;' }}>License
                    page</a>
                </strong>

                <strong className="d-block">Disclaimers:</strong>

                <p>
                  May apply for a variety of reasons, including married couples, recent home buyers, and a range of security and safety enhancing features, such as gated community location, installed smoke alarm, burglar alarm and others.
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopUp;
